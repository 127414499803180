import React from 'react';
import {graphql} from "gatsby";
import WebsiteDefaultPage from '../../components/Page/Base';
import { JoinSection } from "../../components/SharedSections";
import {LIGHT_DARK} from "../../constants";
import NewsSection from "../../components/PageSpecific/community/news/NewsSection";

const News = ( { data } ) => {
  return (
    <WebsiteDefaultPage pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <NewsSection data={data}/>
      <JoinSection />
    </WebsiteDefaultPage>
  );
};

export default News;

export const query = graphql`
  query NewsPageQuery {
   news: allSanityNews(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          newsURL
          date(formatString: "MMMM DD, YYYY")
          title
          logo {
            asset {
              gatsbyImageData(fit: FILL, placeholder: BLURRED)
            }
          }        
        }
      }
    }
  }
`