import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { ArrowRight } from '../../../Svg';
import {
  bodyDefault,
  Color,
  flexGap,
  h3Desktop,
  hover,
  hoverChild,
  marginBottom,
  marginTop,
  maxWidth,
  padding,
  typographyByBreakpoint,
  Width,
  xxlDesktop,
  xxlMobile,
  position,
  width,
  height,
} from '../../../../styles';
import { Button } from '../../../Gui/Button';

const Wrapper = styled.section`
  position: relative;
  ${maxWidth('32.7rem', '68.8rem', '120rem')};
  ${marginTop(136, 152, 206)};
  ${marginBottom(80, 100, 120)};
  margin-inline: auto;
`;

const Content = styled.div`
  h1 {
    ${typographyByBreakpoint(xxlMobile, xxlMobile, xxlDesktop)};
    color: ${Color.SEAFOAM_GREEN};
  }
  .news-cards {
    ${maxWidth('100%', '100%', '99.4rem')};
    ${marginTop(32, 48, 32)};
    margin-inline: auto;
    display: grid;
    grid-template-columns: 1fr; // Single column on mobile
    grid-gap: 16px;
    row-gap: 36px;
    
    @media screen and (min-width: ${Width.BREAK_XL}px) {
      grid-template-columns: repeat(2, 1fr); // Two columns on larger screens 
    }
  }
`;

export const Vector = styled.div`
  position: absolute;
  ${position('top', -176, -280, -220)};
  ${position('left', 76, 170, 460)};
  ${width('39.1rem', '60.8rem', '88.2rem')};
  ${height('39.1rem', '60.8rem', '88.2rem')};
  border-radius: 50%;
  background: linear-gradient(136.25deg, rgba(130, 138, 188, 0.05) 14.98%, rgba(249, 249, 249, 0.4) 93.71%);
  transform: matrix(0.97, -0.26, -0.26, -0.97, 0, 0);
  overflow: hidden;
`;

const CardWrapper = styled.a`
  ${maxWidth('100%', '57rem', '46.5rem')};
  width: 100%;
  ${padding('24px', '24px', '40px')};
  background-color: ${Color.WHITE};
  box-shadow: 33.7444px 33.7444px 60.7399px rgba(166, 180, 200, 0.1), 0 14.1616px 14.1616px rgba(0, 0, 0, 0.08)};
  ${hover('transform', 'none', 'translate(3px, -5px)')};
  outline: 1px solid transparent;
  z-index: 1;
  display: flex;
  flex-direction: column; 
  justify-content: space-between; // Equally spaces elements vertically in the newscard
  
  h2 {
    ${h3Desktop};
    color: ${Color.BLACK};
    text-align: left;
    margin: 0;
  }
  p {
    color: ${Color.GRAY};
    ${bodyDefault};
    margin-top: 8px;
    text-align: left;
    margin: 0;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    ${marginTop(24, 24, 40)};
    padding-top: 16px;
    border-top: 4px solid ${Color.GREEN};
    .gatsby-image-wrapper img {
      height: 4rem;
      width: unset;
    }
    .button-wrapper {
      display: flex;
      ${hoverChild('svg path', 'stroke', Color.BLACK, Color.WHITE)};
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
`;

const NewsCard = ({ name, date, url, logo }) => {
  return (
    <CardWrapper href={url}>
      <div>
        <h2>{name}</h2>
        <p>{date}</p>
      </div>
      <div className={'card-footer'}>
        <GatsbyImage image={logo.asset.gatsbyImageData} alt={''} />
        <Button theme={'BORDER_BLACK'}>{ArrowRight}</Button>
      </div>
    </CardWrapper>
  );
};

const NewsSection = ({ data }) => {
  const news = data && data.news && mapEdgesToNodes(data.news);
  return (
    <Wrapper>
      <Vector />
      <Content>
        <h1>News</h1>
        <div className={'news-cards'}>
          {news.map((node) => (
            <NewsCard key={node.id} name={node.title} date={node.date} url={node.newsURL} logo={node.logo} />
          ))}
        </div>
      </Content>
    </Wrapper>
  );
};

function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export default NewsSection;